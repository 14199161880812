/* eslint-disable camelcase */
import { AxiosResponse } from "axios"

import { SummaryQueryType } from "@store/search/types"
import {
  AnswerViewType,
  QuestionFeedbackType,
} from "@framework/constants/search-results"
import {
  AnswerData,
  AnswerSourceMetaData,
  SearchFilterType,
} from "@framework/types/search"
import { Product } from "@framework/types/product"
import { Company } from "@framework/types/company"

import { DefaultSuccessResponse } from "./common/types"
import HttpService, { StreamEvents } from "./http.service"

type SearchStreamMeta = {
  id: string
  queryType?: SummaryQueryType
  threadId?: string
}

export type StreamSearchResult = {
  type: "SEARCH_RESULT"
  message: null
  data: {
    searchResults: AnswerResponseData
  }
  meta?: SearchStreamMeta
}

export type StreamSearchStatus = {
  type: "STATUS_MESSAGE" | "ERROR"
  message: string
  data: null
  meta?: SearchStreamMeta
}

export type StreamSearchSummary = {
  type: "SUMMARY"
  message: string | null
  data: {
    summary: {
      confidence: number
      keyInfo: string
      keyInfoList: string[]
      summary: string
      // or
      text: string
      source: string
      questionId?: string
    }
  }
  meta?: SearchStreamMeta
}

export type StreamSearchSummaryDelta = {
  type: "SUMMARY_DELTA"
  message: string | null
  data: {
    text: string
    source?: string
  }
  meta?: SearchStreamMeta
}

export type StreamSuggestedQuestion = {
  type: "SUGGESTED_QUESTIONS"
  message: string | null
  data: {
    suggested_questions: string[]
    source?: string
  }
  meta?: SearchStreamMeta
}

export type StreamMessage =
  | StreamSearchResult
  | StreamSearchStatus
  | StreamSearchSummary
  | StreamSearchSummaryDelta
  | StreamSuggestedQuestion

export type AnswerResponseData = {
  answer: {
    answers: AnswerData[]
  }
  filesInfo: AnswerSourceMetaData[]
  filesWithHit: {
    [key: string]: AnswerSourceMetaData
  }
}

export interface GetSourceInfoResponse {
  data: {
    source: string
    file_size: number
  }[]
  message: string
  status: "SUCCESS"
}

export interface GetSuggestionsResponse extends DefaultSuccessResponse {
  data: string[]
}

export interface UpdateAnswerUpVote extends DefaultSuccessResponse {
  data: {
    current_user_vote: 1 | 0
  }
}

export type SendQuestionFeedbackResponse = DefaultSuccessResponse

export interface UpdateAnswerFeedbackRequestBody {
  question: string
  answer: string
  userFeedback: QuestionFeedbackType
  comment: string
  channelName: string // === avatarName
  avatarId: string
  summaryType?: string
  productSolutionId?: string
  rating?: number
}

export interface GetQueryHighlightsResponse {
  products: Product[]
  companies: Company[]
}

class SearchAPI extends HttpService {
  getUnstructuredSearchStream = (
    filter: SearchFilterType,
    events: StreamEvents
  ) =>
    this.postJSONStream(
      "ts/v2/integrated-query",
      { ...filter, streamFlag: true },
      events
    )

  getFilesInfo = (
    filesInfo: { source: string }[]
  ): Promise<AxiosResponse<GetSourceInfoResponse>> =>
    this.post("ts/query/files-info", { filesInfo })

  getUsersQuestions = (
    avatar: string,
    productSolutionId: string
  ): Promise<AxiosResponse<GetSuggestionsResponse>> =>
    this.get(`ts/${avatar}/question/my-questions`, true, { productSolutionId })

  sendAnswerFeedback = (
    request: UpdateAnswerFeedbackRequestBody
  ): Promise<AxiosResponse<SendQuestionFeedbackResponse>> =>
    this.post("ts/question/user-feedback", request)

  upVoteAnswer = (
    question: string,
    answer: AnswerData,
    answerType: AnswerViewType,
    avatar: string,
    avatarId: string
  ): Promise<AxiosResponse<UpdateAnswerUpVote>> =>
    this.post("ts/v2/vote", {
      question,
      answer,
      answerType,
      avatarName: avatar,
      avatarId,
    })

  getQueryHighlights = (
    summary: string,
    questionId: string
  ): Promise<AxiosResponse<GetQueryHighlightsResponse>> =>
    this.post(
      "ts/v2/integrated-query/highlights",
      {
        summary,
        questionId,
      },
      true
    )
}

export default new SearchAPI()
