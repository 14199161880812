import clsx from "clsx"
import React from "react"

import Text from "@components/ui/Typography/Text"
import Avatar from "@components/ui/Avatar/Avatar"
import { ExpertInsightCitationData } from "@store/search/search-citations.store"
import { getFullName } from "@pages/questions/utils"

import styles from "./ExpertAnswer.module.sass"

interface ExpertAnswerProps {
  details: ExpertInsightCitationData
}

const ExpertAnswer: React.FC<ExpertAnswerProps> = ({ details }) => {
  return (
    <div className={clsx(styles.root)}>
      <Text variant="h3" className={styles.question}>
        Q: {details.question}
      </Text>

      {details.verifiedBy.length ? (
        <div className={styles.expertsContainer}>
          <Text variant="h5">Contributors</Text>
          <div className={styles.experts}>
            {details.verifiedBy.map((it) => {
              const fullname = getFullName(it)
              return (
                <div className={styles.expertDetails} key={it.id}>
                  <Avatar src={it.avatarURL} name={fullname} />
                  <Text variant="body2" color="text70Color">
                    {fullname}
                  </Text>
                </div>
              )
            })}
          </div>
        </div>
      ) : details.detailsLoadingError ? (
        <div className={styles.expertsContainer}>
          <Text variant="h5">Contributors</Text>
          <Text variant="body2" color="text50Color">
            Failed to load contributors
          </Text>
        </div>
      ) : null}
    </div>
  )
}

export default ExpertAnswer
