import React from "react"
import clsx from "clsx"
import groupBy from "lodash/groupBy"

import Column from "@components/ui/BaseTable/Column"
import Row from "@components/ui/BaseTable/Row"
import Table from "@components/ui/BaseTable/Table"
import TextColumn from "@components/ui/SimpleTable/TextColumn"
import Text from "@components/ui/Typography/Text"
import NotFound from "@components/ui/NotFound/NotFound"
import { LinkProducts as LinkProductsType } from "@framework/types/product"

import styles from "./index.module.sass"

export type LinkProductsProps = {
  products: LinkProductsType[]
}

const LinkProducts: React.FC<LinkProductsProps> = ({ products }) => {
  if (products.length === 0) {
    return <NotFound />
  }
  const groupedAttributes = groupBy(products, "relationshipType")
  return (
    <>
      {Object.entries(groupedAttributes).map(([key, value]) => (
        <>
          <Text variant="h4" className={styles.header}>
            {key} ({value.length})
          </Text>
          <div className={clsx(styles.card, styles.attributes)}>
            <Table
              hideBorder
              header={
                <Row>
                  <TextColumn>Product</TextColumn>
                  <TextColumn>Manufacturer</TextColumn>
                  <TextColumn>Additional Notes</TextColumn>
                </Row>
              }
            >
              {value.map((item) => (
                <Row>
                  <Column> {item.relatedProduct.name}</Column>
                  <Column> {item.relatedProduct.company.name}</Column>
                  <Column> {item.note}</Column>
                </Row>
              ))}
            </Table>
          </div>
        </>
      ))}
    </>
  )
}

export default LinkProducts
