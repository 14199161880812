import React from "react"
import clsx from "clsx"
import { useNavigate } from "react-router-dom"
import moment from "moment"
import { useAlert } from "react-alert"

import { AnswerStatus, ExpertQuestion } from "@framework/types/question"
import mainRoutes from "@root/main.routes"
import { useStore } from "@store/index"
import Text from "@components/ui/Typography/Text"
import VerifyButton from "@components/prototypes/ExpertOptions/VerifyAnswer/VerifyButton"
import { getFullName } from "@pages/questions/utils"
import Markdown from "@components/ui/Typography/Markdown"
import Templates from "@components/ui/Templates"
import UsersBubbles from "@components/ui/UsersBubbles/UsersBubbles"
import Icon from "@components/ui/Icon/Icon"
import Button from "@components/ui/Button/Button"
import useToggle from "@components/hooks/useToggle"
import MarkdownEditor from "@components/prototypes/ResultsCard/AnswerSection/MarkdownEditor"

import QuestionCard from "../QuestionCard/QuestionCard"

import styles from "./ExpertQuestionDetails.module.sass"

interface ExpertQuestionDetailsProps {
  className?: string
  questionData: ExpertQuestion
}

const ExpertQuestionDetails: React.FC<ExpertQuestionDetailsProps> = ({
  className,
  questionData,
}) => {
  const alert = useAlert()

  const editToggle = useToggle()

  const navigate = useNavigate()
  const {
    knowledgeStore: { updateExpertQuestion, loadExpertQuestions },
    userStore: { user },
    restrictionsStore: access,
  } = useStore()

  const currentAnswer = questionData.answer || questionData.summary || ""

  const handleSubmit = async (answer: string) => {
    const success = await updateExpertQuestion(
      questionData.id,
      AnswerStatus.ANSWERED,
      answer
    )

    if (!success) {
      alert.error("Failed to update question")
      return
    }

    loadExpertQuestions()
    navigate(mainRoutes.question(questionData.id), { replace: true })
  }

  const lastVerification = questionData.verifiedBy[0]

  const isAnyVerified = !!questionData.verifiedBy.length

  const isVerifiedByMe =
    questionData.verifiedBy.find((it) => it.id === user?.id) != null

  const isValidAnswer = !!currentAnswer

  return (
    <div className={clsx(styles.root, className)}>
      <QuestionCard data={questionData} />

      <div className={styles.editor}>
        {lastVerification != null && (
          <Text variant="h5" color="text70Color" align="end">
            {"Last Suggestion: "}
            {moment.utc(lastVerification.updatedAt).fromNow()}
            {" by "}
            {getFullName(lastVerification)}
          </Text>
        )}

        {editToggle.isOpened ? (
          <MarkdownEditor
            initialValue={currentAnswer}
            onSave={handleSubmit}
            onClose={editToggle.handleToggle}
          />
        ) : (
          <>
            <div className={styles.answerContainer}>
              <div className={styles.content}>
                <Markdown>{currentAnswer || "No answer provided yet"}</Markdown>
              </div>
            </div>

            <Templates.Header
              align="end"
              left={
                isAnyVerified && (
                  <div className={styles.verified}>
                    <Text variant="h5" className={styles.alignCenter}>
                      <Icon name="shield-check-fill" color="green" inline />
                      {" Verified by Expert"}
                    </Text>

                    <UsersBubbles users={questionData.verifiedBy} />
                  </div>
                )
              }
              right={
                <div className={styles.control}>
                  {access.canVerifyAnswer && (
                    <VerifyButton
                      isVerified={isVerifiedByMe}
                      onClick={() => handleSubmit(currentAnswer)}
                      disabled={!isValidAnswer}
                    />
                  )}

                  {access.canSuggestImprovements && (
                    <Button
                      before={<Icon name="sparkling" />}
                      size="small"
                      color="primary"
                      onClick={editToggle.handleToggle}
                    >
                      {currentAnswer ? "Improve this Answer" : "Suggest Answer"}
                    </Button>
                  )}
                </div>
              }
            />
          </>
        )}
      </div>
    </div>
  )
}

export default ExpertQuestionDetails
