import React from "react"
import SpeechRecognition from "react-speech-recognition"

const speechRecognizer = SpeechRecognition.getRecognition()

export type Options = {
  continuous?: boolean
  disabled?: boolean
  onStart?: () => void
  onChange?: (newValue: string) => void
  onEnd?: () => void
}

export type SpeechRecognizerState = {
  listening: boolean
  disabled: boolean
  transcript: string
  toggle: (value?: boolean) => void
}

export const useSpeechRecognizer = ({
  continuous,
  disabled = false,
  onChange,
  onStart,
  onEnd,
}: Options = {}): SpeechRecognizerState => {
  const [inProgress, setInProgress] = React.useState<boolean>(false)
  const [currentValue, setCurrentValue] = React.useState<string>("")

  const startRecording = () => {
    if (speechRecognizer == null) return
    speechRecognizer.continuous = !!continuous
    speechRecognizer.lang = "en"
    speechRecognizer.start()
  }

  const stopRecording = () => {
    if (speechRecognizer == null) return
    speechRecognizer.stop()
    speechRecognizer.continuous = false
  }

  const toggle = (value: boolean = !inProgress) => {
    if (!SpeechRecognition.browserSupportsSpeechRecognition) return
    if (speechRecognizer == null) return

    if (value === inProgress) return

    if (value) startRecording()
    else stopRecording()
  }

  React.useEffect(() => {
    if (!SpeechRecognition.browserSupportsSpeechRecognition()) return undefined
    if (speechRecognizer == null) return undefined
    if (disabled) return undefined

    // handlers

    const handleResult = (e: SpeechRecognitionEvent) => {
      const value = [...e.results].map((it) => it[0].transcript).join()
      setCurrentValue(value)
      onChange?.(value)
    }

    const handleStart = () => {
      setInProgress(true)
      setCurrentValue("")
      onStart?.()
      onChange?.("")
    }

    const handleEnd = () => {
      setInProgress(false)
      onEnd?.()
      speechRecognizer.abort()
    }

    // subscriptions

    speechRecognizer.addEventListener("start", handleStart)
    speechRecognizer.addEventListener("result", handleResult)
    speechRecognizer.addEventListener("end", handleEnd)

    return () => {
      speechRecognizer.removeEventListener("start", handleStart)
      speechRecognizer.removeEventListener("result", handleResult)
      speechRecognizer.removeEventListener("end", handleEnd)

      stopRecording()
      handleEnd()
    }
  }, [disabled])

  return {
    toggle,
    listening: inProgress,
    transcript: currentValue,
    disabled,
  }
}
