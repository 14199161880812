import React from "react"
import { observer } from "mobx-react-lite"
import clsx from "clsx"

import { useController, useStore } from "@store"
import Icon from "@components/ui/Icon/Icon"
import Skeleton from "@components/ui/Skeleton/Skeleton"
import useMediaQuery from "@components/hooks/useMediaQuery"
import { AppMediaQueries } from "@framework/constants/app"
import CollapsibleContextProvider from "@components/ui/Collapsable/CollapsibleContext"
import SearchSummaryBlockStore from "@store/search/search-summary-block.store"

import InfoCard from "./InfoCard"
import LineItem from "./LineItem"
import Question from "./Question"
import { getCompanyOptions, getProductOptions } from "./SolutionOptions"

import styles from "./SidePanel.module.sass"

export type SidePanelProps = {
  isSearching: any
}

const SidePanel: React.FC<SidePanelProps> = observer(({ isSearching }) => {
  const isSmallScreen = useMediaQuery(AppMediaQueries.maxDesktop)

  const {
    restrictionsStore: access,
    factFinderSolutionStore: { searchFlowStore, highlights, loadingHighlights },
  } = useStore()
  const { factFinderSolutionController } = useController()

  const [suggestedQuestions, setSuggestedQuestion] = React.useState<string[]>(
    []
  )

  const summaryBlock = React.useMemo(() => {
    const lastSearchId = searchFlowStore.flowSequence.at(-1)

    if (lastSearchId == null) return null

    const data = searchFlowStore.getById(lastSearchId)

    const block = data.blocks.find(
      (it) => it instanceof SearchSummaryBlockStore
    ) as SearchSummaryBlockStore | undefined

    return block ?? null
  }, [searchFlowStore.flowSequence.length, isSearching])

  const fetchHighlights = async (summaryBlock: SearchSummaryBlockStore) => {
    const rawAnswer = summaryBlock.searchSummary.summary || ""
    if (rawAnswer) {
      await factFinderSolutionController.getQueryHighlights(
        rawAnswer,
        searchFlowStore.questionId
      )
    }
  }

  const updateSuggestedQuestions = (summaryBlock: SearchSummaryBlockStore) => {
    setSuggestedQuestion(summaryBlock.searchSummary.suggestedQuestion || [])
  }

  React.useEffect(() => {
    if (searchFlowStore.flowSequence?.length) return

    setSuggestedQuestion([])
  }, [searchFlowStore.flowSequence?.length])

  React.useEffect(() => {
    if (isSearching || summaryBlock == null) return

    updateSuggestedQuestions(summaryBlock)

    if (!access.showSearchCompanies && !access.showSearchProducts) return

    fetchHighlights(summaryBlock)
  }, [isSearching, summaryBlock])

  const companiesAvailable =
    !!highlights?.companies?.length && access.showSearchCompanies

  const productsAvailable =
    !!highlights?.products?.length && access.showSearchProducts

  const questionsAvailable = !!suggestedQuestions?.length

  return (
    <CollapsibleContextProvider singleActive>
      <div
        className={clsx(styles.root, {
          [styles.visible]:
            companiesAvailable || productsAvailable || questionsAvailable,
          [styles.moveRight]: !isSmallScreen,
        })}
      >
        {questionsAvailable && (
          <InfoCard
            icon={<Icon name="question-mark" />}
            heading="Suggested Questions"
          >
            <div className={styles.lineItemWrapper}>
              {suggestedQuestions.map((question) => (
                <Question question={question} />
              ))}
            </div>
          </InfoCard>
        )}

        {productsAvailable && (
          <InfoCard icon={<Icon name="parcel" />} heading="Products">
            <div className={styles.lineItemWrapper}>
              {loadingHighlights && (
                <Skeleton
                  lineHeight={30}
                  minWidth={100}
                  rounded
                  className={styles.skeletonLoader}
                />
              )}
              {highlights?.products?.map((product) => (
                <LineItem
                  key={product.name}
                  name={product.name}
                  product={product}
                  options={getProductOptions(product)}
                />
              ))}
            </div>
          </InfoCard>
        )}

        {companiesAvailable && (
          <InfoCard icon={<Icon name="factory" />} heading="Accounts">
            <div className={styles.lineItemWrapper}>
              {loadingHighlights && (
                <Skeleton
                  lineHeight={30}
                  minWidth={100}
                  rounded
                  className={styles.skeletonLoader}
                />
              )}
              {highlights?.companies?.map((company) => (
                <LineItem
                  key={company.name}
                  name={company.name}
                  options={getCompanyOptions(company)}
                  info={company.url}
                />
              ))}
            </div>
          </InfoCard>
        )}
      </div>
    </CollapsibleContextProvider>
  )
})

export default SidePanel
