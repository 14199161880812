import React, { useEffect } from "react"
import { FormikProvider, useFormik, FieldArray } from "formik"
import * as Yup from "yup"
import { observer } from "mobx-react-lite"
import { useAlert } from "react-alert"

import BaseModal from "@components/modals/components/BaseModal/BaseModal"
import ModalTitle from "@components/modals/components/ModalTitle/ModalTitle"
import { ModalsTypes } from "@components/modals/constants"
import useModal from "@components/modals/useModal"
import Text from "@components/ui/Typography/Text"
import { TextAreaInput } from "@components/ui/TextInput/TextInput"
import { useController, useStore } from "@store"
import Button from "@components/ui/Button/Button"
import MultiSelect from "@components/ui/MultiSelect/MultiSelect"
import {
  LinkProductsData,
  ProductRelationshipType,
} from "@framework/types/product"
import Chip from "@components/ui/Chip/Chip"
import Icon from "@components/ui/Icon/Icon"
import { V2_Products } from "@store/product/product.store"
import Loader from "@components/ui/Loader/BarLoader"
import { LinkProductInfo } from "@pages/product-page/components/edit/ProductsTable"

import styles from "./LinkProductRelationshipModal.module.sass"

export interface LinkProductRelationshipModalProps {
  productId: string
  onLinkProductRelationShip: (data: LinkProductInfo[]) => void
  linkProductData?: LinkProductsData
  product?: V2_Products
  onUpdateLinkProduct: (data: LinkProductsData) => void
  invalidProductIds: string[]
}

const validationSchema = Yup.object({
  products: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.string().required(),
        name: Yup.string().required(),
      })
    )
    .required("At least one product is required"),
  notes: Yup.string().optional(),
  type: Yup.string().required(),
})

const LinkProductRelationshipModal: React.FC<LinkProductRelationshipModalProps> =
  observer(
    ({
      onLinkProductRelationShip,
      productId,
      linkProductData,
      product,
      onUpdateLinkProduct,
      invalidProductIds,
    }) => {
      const modal = useModal(ModalsTypes.LINK_PRODUCT_RELATIONSHIP_MODAL)
      const linkProductsModal = useModal(ModalsTypes.LINK_PRODUCTS_MODAL)
      const {
        productComparisonStore: { productStore },
      } = useStore()
      const alert = useAlert()
      const {
        productController: { linkProduct, updateLinkProduct },
      } = useController()
      const { isLinkingProduct, isUpdatingLinkProduct } = productStore

      const closeModal = () => {
        modal.hideModal()
      }

      const formik = useFormik({
        initialValues: productStore.linkProductRelationShipModalData,
        validationSchema,
        onSubmit: async (values) => {
          const linkProducts: LinkProductsData[] = values.products.map(
            (item) => ({
              relatedProductId: item.id,
              relationshipType: values.type,
              note: values.notes,
            })
          )

          if (linkProductData?.id) {
            await updateLinkProductData()
            return
          }

          const res = await linkProduct(productId, linkProducts)
          if (res?.data) {
            alert.success("Successfully linked product")
            const returnValue = values.products.map((product) => {
              const linkProductId = res?.data.find(
                (item) => item.relatedProductId === product.id
              )?.id
              return {
                relatedProductId: product.id,
                relationshipType: values.type,
                note: values.notes,
                product,
                id: linkProductId,
              }
            })
            onLinkProductRelationShip(returnValue)
            closeModal()
          }
        },
      })

      const updateLinkProductData = async () => {
        if (!linkProductData?.id) return
        const data = {
          id: linkProductData.id,
          productId,
          relatedProductId: linkProductData?.relatedProductId,
          relationshipType: formik.values.type,
          note: formik.values.notes,
        }
        const success = await updateLinkProduct(productId, data)
        if (success) {
          alert.success("Successfully updated linked product")
          onUpdateLinkProduct(data)
          closeModal()
        }
      }

      useEffect(() => {
        productStore.linkProductRelationShipModalData = formik.values
      }, [formik.values])

      const handleAddProducts = () => {
        linkProductsModal.showModal({
          invalidProductIds: [
            ...invalidProductIds,
            ...formik.values.products.map((p: any) => p.id),
            productId,
          ],
          onSave: (newProducts) => {
            const allProducts = [
              ...productStore.linkProductRelationShipModalData.products,
              ...newProducts,
            ]
            productStore.linkProductRelationShipModalData.products = allProducts
            formik.setFieldValue("products", allProducts)
          },
        })
      }

      useEffect(() => {
        if (
          !linkProductData?.id &&
          formik.values.products.length === 0 &&
          !productStore.linkProductRelationShipModalData.addModalShowed
        ) {
          handleAddProducts()
          productStore.linkProductRelationShipModalData.addModalShowed = true
        }
      }, [formik.values.products])

      useEffect(() => {
        if (linkProductData?.id) {
          formik.setFieldValue("products", [product])
          formik.setFieldValue("notes", linkProductData.note)
          formik.setFieldValue("type", linkProductData.relationshipType)
          formik.setFieldValue("addModalShowed", true)
        }
      }, [])

      return (
        <BaseModal
          title={<ModalTitle titleText="Link Product" />}
          onClose={closeModal}
          className={styles.root}
        >
          <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit} className={styles.form}>
              <div className={styles.fullWidthInput}>
                <Text variant="body2" className={styles.formInputHeading}>
                  Products
                </Text>
                <FieldArray
                  name="products"
                  render={(arrayHelpers) => (
                    <div className={styles.productsList}>
                      {formik.values.products.map(
                        (product: any, index: number) => (
                          <Chip
                            color="secondary"
                            variant="rounded"
                            disabled={!!linkProductData?.id}
                            before={
                              !linkProductData?.id && <Icon name="cross" />
                            }
                            key={product.id}
                            onClick={() =>
                              linkProductData?.id
                                ? null
                                : arrayHelpers.remove(index)
                            }
                          >
                            {product.name}
                          </Chip>
                        )
                      )}
                      {!linkProductData?.id && (
                        <Button
                          color="secondary"
                          size="small"
                          before={<Icon name="plus" />}
                          onClick={handleAddProducts}
                        >
                          Add
                        </Button>
                      )}
                    </div>
                  )}
                />
              </div>
              <div className={styles.fullWidthInput}>
                <Text variant="body2" className={styles.formInputHeading}>
                  Relationship Type *
                </Text>
                <MultiSelect
                  isRounded
                  isSingleSelect
                  placeholder="Select relationship types..."
                  onSelectionChange={(selectedItems) => {
                    formik.setFieldValue("type", selectedItems[0])
                  }}
                  defaultSelectedItems={
                    formik.values.type ? [formik.values.type] : []
                  }
                  variant="secondary"
                  options={Object.values(ProductRelationshipType).map(
                    (type) => ({
                      id: type as string,
                      name: type as string,
                      value: type as string,
                    })
                  )}
                  error={!!formik.errors.type?.length}
                />
              </div>
              <div className={styles.fullWidthInput}>
                <Text variant="body2" className={styles.formInputHeading}>
                  Additional Notes
                </Text>
                <TextAreaInput
                  value={formik.values.notes}
                  placeholder="Add notes here"
                  onChange={(event) => {
                    formik.setFieldValue(
                      "notes",
                      (event.target as HTMLTextAreaElement).value
                    )
                  }}
                />
              </div>
              <div className={styles.footer}>
                <Button size="medium" variant="outlined" onClick={closeModal}>
                  Cancel
                </Button>
                <Button
                  type="submit"
                  size="medium"
                  color="primary"
                  disabled={
                    !formik.isValid || isLinkingProduct || isUpdatingLinkProduct
                  }
                  after={
                    (isLinkingProduct || isUpdatingLinkProduct) && (
                      <Loader size="small" />
                    )
                  }
                >
                  Save
                </Button>
              </div>
            </form>
          </FormikProvider>
        </BaseModal>
      )
    }
  )

export default LinkProductRelationshipModal
