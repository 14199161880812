import React, { useEffect } from "react"
import { Field, FieldProps } from "formik"
import { toJS } from "mobx"
import { observer } from "mobx-react-lite"

import Templates from "@components/ui/Templates"
import FilterSidebar from "@components/prototypes/FilterSidebar"
import FilterButton from "@components/ui/Button/FilterButton"
import { useController, useStore } from "@store"
import FormTextInput from "@components/prototypes/form-elements/FormTextInput"
import Icon from "@components/ui/Icon/Icon"
import FormSwitch from "@components/prototypes/form-elements/FormSwitch"
import ProductFilterManufacturerField from "@pages/product-page/components/ProductsFilter/ProductFilterManufacturerField"

import IdentifierFilterHeader from "../ProductIdentifiersList/IdentifierFilterHeader"

import styles from "./IdentifierFilterModal.module.sass"

interface IdentifierFilterProps {}

interface IdentifierFilterButtonProps {
  onToggle: () => void
  onReset: () => void
}

const IdentifierFilterButton: React.FC<IdentifierFilterButtonProps> = ({
  onToggle,
  onReset,
}) => (
  <FilterButton
    size="big"
    color="secondary"
    counter={0}
    onOpen={onToggle}
    onClean={onReset}
  >
    Filter
  </FilterButton>
)

const IdentifierFilterSearchInput: React.FC<FieldProps<string[]>> = observer(
  ({ form, field: { name } }) => {
    const {
      identifierStore: { activeFilter },
    } = useStore()

    const {
      identifierController: { updateFilterSearchQuery },
    } = useController()

    useEffect(() => {
      updateFilterSearchQuery(form.values[name])
    }, [form.values[name]])

    useEffect(() => {
      form.setFieldValue("manufacturers", activeFilter.manufacturers)
    }, [activeFilter])

    return (
      <FormTextInput
        value={form.values[name]}
        name="searchQuery"
        placeholder="Search"
        before={<Icon name="search" />}
      />
    )
  }
)

const options = [
  { name: "true", value: "Include" },
  { name: "false", value: "Exclude" },
]

const IdentifierFilterForm: React.FC = observer(() => {
  const {
    identifierStore: { filterSearchQuery },
  } = useStore()
  return (
    <>
      <FormSwitch items={options} name="includes" />

      <Field
        name="manufacturers"
        label="Manufacturer"
        valueKey="id"
        component={ProductFilterManufacturerField}
        searchQuery={filterSearchQuery}
      />
    </>
  )
})

const IdentifierFilter: React.FC<IdentifierFilterProps> = observer(() => {
  const {
    identifierController: { updateActiveFilter },
  } = useController()

  const { identifierStore } = useStore()

  const { activeFilter } = identifierStore

  return (
    <Templates.Header
      className={styles.filterHeader}
      left={
        activeFilter.manufacturers.length ? <IdentifierFilterHeader /> : null
      }
      right={
        <FilterSidebar
          initialValue={{
            identifiers: [],
            manufacturers: [],
            searchQuery: "",
            includes: "true",
          }}
          defaultValue={{
            identifiers: [],
            manufacturers: [],
            searchQuery: "",
            includes: "true",
          }}
          onChange={(newValue) => {
            updateActiveFilter({
              manufacturers: toJS(newValue.manufacturers) || [],
              includes: toJS(newValue.includes) === "true",
            })
          }}
          targetSlot={IdentifierFilterButton}
          headerSlot={
            <Field name="searchQuery" component={IdentifierFilterSearchInput} />
          }
        >
          <IdentifierFilterForm />
        </FilterSidebar>
      }
    />
  )
})

export default IdentifierFilter
