import { AxiosResponse } from "axios"
import { Observable } from "rxjs"

import {
  BusinessUnit,
  Company,
  CompanyTheme,
  Filename,
  ProductThemeType,
} from "@framework/types/company"
import {
  AddProductRequest,
  Attribute,
  GetComparisonReportParams,
  LinkProductsData,
  LinkProductsResponseData,
  ListResponse,
  PatchProductRequest,
  Product,
  ProductDetails,
  ProductListQueryRequest,
  ProductListResponsePaginationMetaData,
  ProductQueryRequest,
  ProductQueryResponse,
} from "@framework/types/product"
import { ProductFilter, SearchFilter } from "@framework/types/product-filter"
import { ReportData } from "@framework/types/workbook-report"
import { V2_Products } from "@store/product/product.store"
import { ConnectorDocument } from "@framework/types/content-manager"

import { DefaultSuccessResponse } from "./common/types"
import HttpService from "./http.service"

export interface GetProductsResponse extends DefaultSuccessResponse {
  data: ProductQueryResponse
}

export interface GetProductByIdResponse extends DefaultSuccessResponse {
  data: Product
}

export interface GetPopularProductsResponse extends DefaultSuccessResponse {
  data: ListResponse<Product>
}

export interface GetNewProductsResponse extends DefaultSuccessResponse {
  data: ListResponse<Product>
}

export interface GetCompanyThemesResponse extends DefaultSuccessResponse {
  data: CompanyTheme[]
}

export interface GetContentResponse {
  data: ConnectorDocument[]
  pagination: { page: number; pageSize: number; totalPages: number }
}

class ProductAPI extends HttpService {
  getAllProducts = (
    filter: ProductQueryRequest
  ): Promise<AxiosResponse<GetProductsResponse>> =>
    this.get("ts/products/query", true, filter)

  getProductById = (
    productId: string
  ): Promise<AxiosResponse<GetProductByIdResponse>> =>
    this.get(`ts/products/${productId}`)

  getPopularProducts = (): Promise<AxiosResponse<GetPopularProductsResponse>> =>
    this.get("ts/products/popular")

  getNewProducts = (): Promise<AxiosResponse<GetNewProductsResponse>> =>
    this.get("ts/products/new")

  getProductFilters = (): Promise<AxiosResponse<ListResponse<ProductFilter>>> =>
    this.get("ts/products/filters")

  getProducts$ = (params: {
    name?: string
    companyIds?: string[]
    pageSize?: number
    pageNum?: number
    filters?: SearchFilter[]
    excludeCompanyIds?: string[]
    excludeIds?: string[]
    // pageToken?: string
  }): Observable<AxiosResponse<ListResponse<Product>>> =>
    this.postStream$("ts/products/search", params)

  getRecommendedProducts = (params: {
    companyIds?: string[]
    excludeCompanyIds?: string[]
    requirementText: string
    requirementFile: File
  }): Promise<AxiosResponse<ListResponse<Product>>> => {
    const data = new FormData()
    data.set("companyIds", JSON.stringify(params.companyIds ?? []))
    data.set(
      "excludeCompanyIds",
      JSON.stringify(params.excludeCompanyIds ?? [])
    )
    data.set("requirementText", params.requirementText)
    data.set("requirementFile", params.requirementFile)

    return this.post("ts/products/recommendations", params)
  }

  getTargetCompanies = (): Promise<AxiosResponse<ListResponse<Company>>> =>
    this.get("ts/companies")

  getTargetFiles = (
    query?: string,
    signal?: AbortSignal
  ): Promise<AxiosResponse<ListResponse<Filename>>> =>
    this.get("ts/data/file/manual/all", true, { query }, null, signal)

  getBusinessUnitList = (
    companyId: string
  ): Promise<AxiosResponse<ListResponse<BusinessUnit>>> =>
    this.get(`ts/companies/${companyId}/business-units`)

  getCompanyThemes = (
    companyId: string,
    options: { businessUnit?: string; type?: ProductThemeType } = {}
  ): Promise<AxiosResponse<GetCompanyThemesResponse>> =>
    this.get(`ts/companies/${companyId}/themes`, true, options)

  generateThemes = (options: {
    companyName: string
    category: string
    businessUnit?: string
  }): Promise<AxiosResponse<GetCompanyThemesResponse>> =>
    this.post(`ts/companies/themes`, options)

  generateAccountPlan = (options: {
    targetCompanyUrl: string
    myCompanyUrl: string
    targetCompanyBu?: string
    additionalContext?: string
  }): Promise<AxiosResponse<string>> => {
    return this.post(`ts/companies/account-plans`, options)
  }

  generateProductComparisonReport = (
    productNames: string[],
    context?: string
  ): Promise<AxiosResponse<string>> => {
    const data: { productNames: string[]; context?: string } = {
      productNames,
    }

    if (context) {
      data.context = context
    }

    return this.post("ts/companies/product-comparison", data)
  }

  generateDocumentComplianceReport = (options: {
    authorityDocument: string
    regulatedDocument: string
  }): Promise<AxiosResponse<string>> =>
    this.post("ts/companies/document-compliance", options)

  generateProductPositioningReport = (options: {
    application: string
    targetProduct: string
    alternateProducts: string[]
  }): Promise<AxiosResponse<string>> =>
    this.post("ts/companies/product-positioning", options)

  getComparisonReport$ = (
    params: GetComparisonReportParams
  ): Observable<AxiosResponse<{ report: string }>> => {
    const data = new FormData()

    data.set("targetCompanyId", params.targetCompanyId)
    data.set("productIds", JSON.stringify(params.productIds))
    data.set("baseCompanyId", params.baseCompanyId)
    if (params.requirementFile)
      data.set("requirementFile", params.requirementFile)
    if (params.businessUnit) data.set("businessUnit", params.businessUnit)
    if (params.requirementText) {
      data.set("requirementText", params.requirementText)
    }

    return this.postStream$("ts/products/comparison-report", data)
  }

  getWorkbookReport = (reportId: string): Promise<AxiosResponse<ReportData>> =>
    this.get(`ts/companies/document-compliance/${reportId}`)

  getAllProductsList = (
    data: ProductListQueryRequest,
    signal: AbortSignal
  ): Promise<
    AxiosResponse<{
      data: V2_Products[]
      pagination: ProductListResponsePaginationMetaData
    }>
  > => this.post("ts/pims/products/search", data, true, { signal })

  createProduct = (data: {
    products: AddProductRequest[]
  }): Promise<
    AxiosResponse<{ success: V2_Products[]; errors: { message: string }[] }>
  > => this.post("ts/pims/products", data, true)

  getProductDetails = (
    productId: string
  ): Promise<AxiosResponse<ProductDetails>> =>
    this.get(`ts/pims/products/${productId}`, true)

  getProductContents = (
    page: number,
    pageSize: number,
    productId: string
  ): Promise<AxiosResponse<GetContentResponse>> =>
    this.get(`ts/pims/products/${productId}/content`, true, {
      pageSize,
      page,
    })

  patchProduct = (
    productId: string,
    data: PatchProductRequest
  ): Promise<AxiosResponse<ProductDetails>> =>
    this.patch(`ts/pims/products/${productId}`, data, true)

  deleteProduct = (productId: string): Promise<AxiosResponse<ProductDetails>> =>
    this.delete(`ts/pims/products/${productId}`, true)

  addAttribute = (
    productId: string,
    attribute: Attribute
  ): Promise<AxiosResponse<Attribute & { product: V2_Products }>> =>
    this.post(`ts/pims/products/${productId}/attributes`, attribute, true)

  updateAttribute = (
    productId: string,
    attribute: Attribute
  ): Promise<AxiosResponse<Attribute>> => {
    const { name, value, unit, id, type } = attribute
    return this.patch(
      `ts/pims/products/${productId}/attributes/${id}`,
      { name, value, unit, type },
      true
    )
  }

  deleteAttribute = (
    productId: string,
    attributeId: string
  ): Promise<AxiosResponse<{ data: Attribute }>> =>
    this.delete(`ts/pims/products/${productId}/attributes/${attributeId}`, true)

  linkProduct = (
    productId: string,
    linkProducts: LinkProductsData[]
  ): Promise<AxiosResponse<LinkProductsResponseData>> =>
    this.post(
      `ts/pims/products/${productId}/linked-products`,
      { data: linkProducts },
      true
    )

  updateLinkProduct = (
    productId: string,
    { id, note, relationshipType }: LinkProductsData
  ): Promise<AxiosResponse<LinkProductsData>> =>
    this.patch(
      `ts/pims/products/${productId}/linked-products/${id}`,
      { note, relationshipType },
      true
    )

  deleteLinkProduct = (
    productId: string,
    linkProductId: string
  ): Promise<AxiosResponse<LinkProductsData>> =>
    this.delete(
      `ts/pims/products/${productId}/linked-products/${linkProductId}`,
      true
    )
}

export default new ProductAPI()
