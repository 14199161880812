import { makeAutoObservable } from "mobx"

import { SearchEntityStore } from "./search-entity.store"

export type SearchEntity = SearchEntityStore

const SHOW_SUGGEST_IMRPOVEMENTS_BANNER = "SHOW_SUGGEST_IMPROVEMENTS_BANNER"
export class SearchFlowStore {
  // injections

  // constructor

  constructor() {
    const showSuggestImprovementsBanner =
      localStorage.getItem(SHOW_SUGGEST_IMRPOVEMENTS_BANNER) !== "false"

    this.showSuggestImprovementsBanner = showSuggestImprovementsBanner
    makeAutoObservable(this)
  }

  // state
  oldThreadId?: string

  flowSequence: string[] = []

  isFlowReset: boolean = false

  flowData: Map<string, SearchEntity> = new Map()

  showSuggestImprovementsBanner: boolean

  verifiedAnswer: boolean = false

  questionId: string = ""

  // actions

  pushSearchInstance = (instance: SearchEntity) => {
    if (!this.flowSequence.includes(instance.id)) {
      this.flowSequence.push(instance.id)
    }
    this.flowData.set(instance.id, instance)
  }

  getById = (blockId: string) => {
    const res = this.findById(blockId)
    if (res == null) throw new Error("SearchFlow store is desynchronized")
    return res
  }

  findById = (searchId: string) => {
    return this.flowData.get(searchId)
  }

  hesSearchEntity = (searchId: string) => {
    return this.flowData.has(searchId)
  }

  reset = () => {
    this.flowSequence = []
    this.flowData = new Map()
    this.oldThreadId = undefined
  }

  setOldThreadId = (id: string) => {
    this.oldThreadId = id
  }

  setQuestionId = (id: string) => {
    this.questionId = id
  }

  isAnyLoading = () => {
    for (let i = this.flowSequence.length - 1; i >= 0; i -= 1) {
      if (this.flowData.get(this.flowSequence[i])?.isLoading) return true
    }
    return false
  }

  isLatestEntity = (entityId: string) => {
    return this.flowSequence.at(-1) === entityId
  }

  setSuggestImprovementsBanner = (show: boolean) => {
    this.showSuggestImprovementsBanner = show
    localStorage.setItem(
      SHOW_SUGGEST_IMRPOVEMENTS_BANNER,
      show ? "true" : "false"
    )
  }
}

export default SearchFlowStore
