import React from "react"
import { observer } from "mobx-react-lite"
import { FieldProps } from "formik"
import without from "lodash/without"
import debounce from "lodash/debounce"

import Dropdown from "@components/prototypes/FilterSidebar/components/Dropdown/Dropdown"
import NotFound from "@components/ui/NotFound/NotFound"
import Loader from "@components/ui/Loader/BarLoader"
import Templates from "@components/ui/Templates"
import { useStore } from "@store/index"
import UserCard from "@components/ui/UserCard/UserCard"
import CheckboxWithLabel from "@components/ui/Checkbox/CheckboxWithLabel"
import { BaseUserData } from "@framework/types/user"
import ListItem from "@components/ui/ListItem/ListItem"
import { SearchContext } from "@components/prototypes/SearchContext"
import InfiniteCollection from "@components/modals/EditModal/components/BaseContainer/InfiniteCollection"
import { getFullName } from "@pages/questions/utils"

import styles from "./UserSelect.module.sass"

const DEBOUNCE_TIMEOUT = 500

export interface UserSelectProps extends FieldProps<string[]> {
  label?: string
  valueKey?: keyof BaseUserData
  showSuperAdmins?: boolean
}

const UserSelect: React.FC<UserSelectProps> = observer(
  ({ label, field, form, valueKey = "email", showSuperAdmins = true }) => {
    const context = React.useContext(SearchContext)

    const [open, setOpen] = React.useState(false)

    const { value = [], name } = field
    const { setFieldValue } = form

    const { adminUserCollection } = useStore()

    const handleUserClick = (newValue: string) => {
      if (value.includes(newValue)) {
        setFieldValue(name, without(value, newValue))
      } else {
        setFieldValue(name, [...value, newValue])
      }
    }

    const debouncedSearch = React.useCallback(
      debounce(adminUserCollection.search, DEBOUNCE_TIMEOUT),
      []
    )

    React.useEffect(() => {
      debouncedSearch(context?.query ?? "")
    }, [context?.query])

    const renderRow = (user: BaseUserData) => {
      const active = value?.includes(user[valueKey] as string)

      return (
        <ListItem
          active={active}
          onClick={() => handleUserClick(user[valueKey] as string)}
        >
          <UserCard
            fullName={getFullName(user) || "Inactive user"}
            metaInfo={user.email}
            avatarSrc={user.avatarURL}
          />
        </ListItem>
      )
    }

    const isChecked = !!value?.length

    const handleUncheck = (e: React.MouseEvent) => {
      if (isChecked) setFieldValue(name, undefined)
      else setOpen(true)
      e.stopPropagation()
    }

    const { isLoading, data: allUsers } = adminUserCollection.state

    const filteredUsers: BaseUserData[] = React.useMemo(() => {
      if (showSuperAdmins) return allUsers
      return allUsers.filter(
        (user) =>
          user != null &&
          !user.userRoles?.some(
            (role) => role.name.toLowerCase() === "superadmin"
          )
      )
    }, [allUsers, showSuperAdmins])

    return (
      <Dropdown
        title={label}
        opened={open}
        onClick={() => setOpen((value) => !value)}
        before={
          <CheckboxWithLabel
            checked={!!value?.length}
            onClick={handleUncheck}
          />
        }
      >
        <Templates.RollScript className={styles.root}>
          <div className={styles.container}>
            {filteredUsers.length === 0 ? (
              isLoading ? (
                <Loader size="large" fluid />
              ) : (
                <NotFound>No Users found</NotFound>
              )
            ) : (
              <InfiniteCollection<BaseUserData>
                total={filteredUsers.length}
                getItem={(idx) => filteredUsers[idx]}
                renderItem={renderRow}
              />
            )}
          </div>
        </Templates.RollScript>
      </Dropdown>
    )
  }
)

export default UserSelect
