import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { useAlert } from "react-alert"
import clsx from "clsx"

import BaseModal from "@components/modals/components/BaseModal/BaseModal"
import ModalTitle from "@components/modals/components/ModalTitle/ModalTitle"
import useModal from "@components/modals/useModal"
import { ModalsTypes } from "@components/modals/constants"
import Button from "@components/ui/Button/Button"
import Icon from "@components/ui/Icon/Icon"
import Text from "@components/ui/Typography/Text"
import { UserAndGroup } from "@framework/types/user"
import { useController, useStore } from "@store"
import useSearch from "@components/hooks/useSearch"
import TextInput from "@components/ui/TextInput/TextInput"
import useDebounce from "@components/hooks/useDebounce"
import Loader from "@components/ui/Loader/BarLoader"
import NotFound from "@components/ui/NotFound/NotFound"
import useInfiniteScroll from "@components/hooks/useInfiniteScroll"

import UserAccessContextMenu from "./UserAccessContextMenu"
import UserAndGroupCard, { getName } from "./UserAndGroupCard"

import styles from "./AccessPermissionModal.module.sass"

export interface AccessPermissionModalProps {
  selectedUsersFunc: () => Promise<UserAndGroup[]>
  solutionId: string
  allowAllEntities?: boolean
  obSubmit?: () => void
}

const AccessPermissionModal: React.FC<AccessPermissionModalProps> = observer(
  ({ selectedUsersFunc, solutionId, allowAllEntities, obSubmit }) => {
    const { hideModal } = useModal(ModalsTypes.ACCESS_PERMISSIONS_MODAL)
    const { entitiesStore } = useStore()
    const [searchProps, searchContext] = useSearch()
    const { debouncedValue: searchQuery, setDebounce } = useDebounce()
    const [loading, setLoading] = useState(false)
    const [allUsers, setAllUsers] = useState<boolean>(allowAllEntities || false)
    const [solutionUsers, setSolutionUsers] = useState<UserAndGroup[]>([])
    const alert = useAlert()

    const { entitiesController } = useController()
    const {
      isUserGroupSelected,
      addOrRemoveSelectedUserAndGroup,
      getUserAndGroups,
      updateSolutionPermission,
    } = entitiesController
    const {
      allUserAndGroups,
      selectedUserAndGroups,
      loadingUserAndGroups,
      userAndGroupsPagination,
      loadingUpdatePermissions,
    } = entitiesStore

    const handleSelectUser = (user: UserAndGroup) => {
      if (!allUsers) {
        addOrRemoveSelectedUserAndGroup(user)
      }
    }

    const loadMoreContent = () => {
      getUserAndGroups({
        page: userAndGroupsPagination.page + 1,
        pageSize: 10,
        query: searchQuery,
      })
    }

    const hasMoreContent =
      (userAndGroupsPagination?.page || 1) <
      (userAndGroupsPagination?.totalPages || 0)

    const lastItemRef = useInfiniteScroll({
      callback: loadMoreContent,
      isLoading: loadingUserAndGroups,
      hasMore: hasMoreContent,
    })

    const handleSubmit = async () => {
      const success = await updateSolutionPermission(
        solutionId,
        solutionUsers,
        allUsers
      )
      if (success) {
        alert.success("Successfully updated permissions")
        hideModal()
        obSubmit?.()
      } else {
        alert.error("Failed to update permissions")
      }
    }

    const handleClose = () => {
      hideModal()
    }

    useEffect(() => {
      setDebounce(searchProps.value)
    }, [searchProps.value])

    useEffect(() => {
      if (loading) return
      entitiesController.getUserAndGroups({
        page: 1,
        pageSize: 10,
        query: searchQuery,
      })
    }, [searchQuery, loading])

    const clearSearch = () => {
      searchContext.setQuery("")
    }

    const loadUserAndGroups = async () => {
      setLoading(true)
      const res = await selectedUsersFunc()
      setSolutionUsers(res)
      setLoading(false)
    }

    useEffect(() => {
      loadUserAndGroups()
    }, [])

    useEffect(() => {
      entitiesController.initializeSelectedUsers(solutionUsers)
    }, [solutionUsers])

    // code to scroll selected users list
    useEffect(() => {
      const container = document.querySelector(`.${styles.selectedUsers}`)

      const handleWheelScroll = (event: Event) => {
        const wheelEvent = event as WheelEvent
        if (container && container.contains(wheelEvent.target as Node)) {
          // Check if the scroll is primarily vertical
          if (Math.abs(wheelEvent.deltaY) > Math.abs(wheelEvent.deltaX)) {
            wheelEvent.preventDefault() // Prevent default vertical scroll
            container.scrollLeft += wheelEvent.deltaY
          }
        }
      }

      if (container) {
        container.addEventListener("wheel", handleWheelScroll as EventListener)
      }

      return () => {
        if (container) {
          container.removeEventListener(
            "wheel",
            handleWheelScroll as EventListener
          )
        }
      }
    }, [])

    return (
      <BaseModal
        title={<ModalTitle titleText="Access Permission" />}
        onClose={handleClose}
        className={styles.root}
      >
        <div className={styles.searchWrapper}>
          <TextInput
            {...searchProps}
            className={styles.searchInput}
            before={<Icon name="search" />}
            placeholder="Search"
            after={
              !!searchProps.value && <Icon name="cross" onClick={clearSearch} />
            }
          />
        </div>
        <div className={styles.selectedUsersContainer}>
          <div className={styles.selectedUsersHeader}>
            <Text variant="body2">
              {allUsers
                ? "Permission will be given to all users"
                : "Selected Users and User Groups"}
            </Text>
            <UserAccessContextMenu
              allowAll={allUsers}
              setAllowAll={setAllUsers}
            />
          </div>
          {!allUsers && (
            <div
              className={clsx(styles.selectedUsers, {
                [styles.disabled]: allUsers,
              })}
            >
              <div>
                {selectedUserAndGroups.map((user) => (
                  <Button
                    key={user.id}
                    variant="outlined"
                    shape="rounded"
                    onClick={() => handleSelectUser(user)}
                    before={<Icon name="user" />}
                    size="tiny"
                    after={<Icon name="cross" />}
                  >
                    {getName(user)}
                  </Button>
                ))}
              </div>
            </div>
          )}
        </div>
        <div
          className={clsx(styles.content, {
            [styles.disabled]: allUsers,
          })}
        >
          {loading ? (
            <Loader fluid size="large" />
          ) : (
            <div className={styles.list}>
              {allUserAndGroups.map((user, index) => (
                <UserAndGroupCard
                  key={user.id}
                  user={user}
                  isSelected={isUserGroupSelected(user.id) || allUsers}
                  onSelect={handleSelectUser}
                  lastItemRef={
                    index === allUserAndGroups.length - 1 ? lastItemRef : null
                  }
                />
              ))}

              {!allUserAndGroups.length && !loadingUserAndGroups && (
                <NotFound />
              )}
              {loadingUserAndGroups && (
                <div className={styles.loader}>
                  <Loader size="small" />
                </div>
              )}
            </div>
          )}
        </div>
        <div className={styles.footer}>
          <Button size="medium" variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            after={loadingUpdatePermissions && <Loader size="small" />}
            size="medium"
            color="primary"
            disabled={loadingUpdatePermissions}
            onClick={handleSubmit}
          >
            Confirm
          </Button>
        </div>
      </BaseModal>
    )
  }
)

export default AccessPermissionModal
