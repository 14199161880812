import { makeAutoObservable } from "mobx"

import { AnswerResponseData } from "@services/search.service"
import { ErrorMessage } from "@framework/types/common"

import FilterEntityStore from "./filter-entity.store"
import SearchMessageBlockStore from "./search-message-block.store"
import SearchSummaryBlockStore from "./search-summary-block.store"

export type SearchBlock = SearchSummaryBlockStore | SearchMessageBlockStore

export type SearchBlockConstructible<T = any> = new (config: {
  id: string
}) => T

export class SearchEntityStore {
  // injections

  blocks: SearchBlock[]

  // constructor

  constructor(config: {
    id: string
    filter: FilterEntityStore
    retryAttempt?: number
  }) {
    this.id = config.id
    this.timestamp = Date.now()
    this.filter = config.filter

    this.retryAttempt = config.retryAttempt ?? 0

    this.blocks = []

    makeAutoObservable(this)
  }

  // state

  readonly id: string

  readonly retryAttempt: number

  timestamp: number

  filter: FilterEntityStore

  isLoading: boolean = false

  error: ErrorMessage | null = null

  suggestedSummary: string | null = null

  isVerifiedSummary: boolean = false

  // actions

  get allRawData() {
    if (this.isLoading) return null
    return this.blocks.reduce<AnswerResponseData[]>((acc, it) => {
      if (
        it instanceof SearchSummaryBlockStore &&
        it.searchAnswersData?.rawAnswer != null
      ) {
        acc.push(it.searchAnswersData.rawAnswer)
      }
      return acc
    }, [])
  }

  getBlockById = (blockId: string) => {
    const res = this.blocks.find((it) => it.id === blockId)
    if (res == null)
      throw new Error(`Search block with id - ${blockId} does not exists`)
    return res
  }

  hasBlock = (blockId: string) => {
    const res = this.blocks.find((it) => it.id === blockId)
    return res != null
  }

  addBlock = <T extends SearchBlock>(block: T): T => {
    this.blocks = [...this.blocks, block]
    return block
  }

  setError = (message: string | null, status: string = "UNEXPECTED_ERROR") => {
    this.error = message
      ? {
          message,
          status,
        }
      : null
  }

  setLoading = (loading: boolean) => {
    this.isLoading = loading
  }

  getOrCreateBlockById = <T extends SearchBlockConstructible>(
    BlockClass: T,
    blockId: string
  ): InstanceType<T> => {
    if (this.hasBlock(blockId)) {
      const block = this.getBlockById(blockId)
      if (block instanceof BlockClass) {
        return block as InstanceType<T>
      }

      throw new TypeError(
        "Stored Block instance is incompatible to passed BlockClass"
      )
    }

    const block = new BlockClass({ id: blockId })
    this.addBlock(block)

    return block
  }

  setSuggestedSummary = (summary: string | null) => {
    this.suggestedSummary = summary
  }

  setVerifiedSummary = (verified: boolean) => {
    this.isVerifiedSummary = verified
  }
}

export default SearchEntityStore
