import { makeAutoObservable } from "mobx"
import { nanoid } from "nanoid"

import {
  AnswerStatus,
  ExpertQuestion,
  ExpertQuestionDTO,
  ExpertQuestionsData,
} from "@framework/types/question"
import { BaseUserData, SimpleUserData } from "@framework/types/user"
import knowledgeService from "@services/knowledge.service"
import { AnswerResponseData } from "@services/search.service"
import { Entity, FailResponse, SuccessResponse } from "@framework/types/common"

export class KnowledgeStore {
  constructor() {
    makeAutoObservable(this)
  }

  isUpdateExpertQuestionLoading: boolean = false

  isSendReminderLoading: boolean = false

  updateExpertQuestionError: string | null = null

  isExpertQuestionsLoading: boolean = false

  expertQuestionsError: string | null = null

  expertQuestions: Partial<ExpertQuestionsData> = {}

  loadExpertQuestions = async () => {
    try {
      this.isExpertQuestionsLoading = true
      this.expertQuestionsError = null
      this.expertQuestions = {}

      const response = await knowledgeService.getExpertQuestions()

      this.expertQuestions = {
        waiting:
          response.data.data.waiting?.map(
            (expertQuestionDTO: ExpertQuestionDTO): ExpertQuestion => ({
              ...expertQuestionDTO,
              verifiedBy: [],
            })
          ) ?? [],
        answered:
          response.data.data.answered?.map(
            (expertQuestionDTO: ExpertQuestionDTO): ExpertQuestion => ({
              ...expertQuestionDTO,
            })
          ) ?? [],
      }
    } catch (error) {
      this.expertQuestionsError = "failed to load expert questions"
    } finally {
      this.isExpertQuestionsLoading = false
    }
    return this.expertQuestionsError
  }

  /**
   * @deprecated
   */
  loadExpertsAssignedToQuestion = async (
    questionId: string,
    questionText: string
  ) => {
    try {
      const response = await knowledgeService.getExpertAssignedToQuestion(
        questionText,
        questionId
      )

      return response.data.data ?? []
    } catch (error) {
      //
    }
    return []
  }

  updateExpertQuestion = async (
    questionId: string,
    status: AnswerStatus,
    answer: string | null = null
  ) => {
    this.isUpdateExpertQuestionLoading = true
    this.updateExpertQuestionError = null
    try {
      await knowledgeService.updateExpertQuestion(
        questionId,
        status,
        answer ?? undefined
      )

      return true
    } catch (error) {
      this.updateExpertQuestionError = "Can't update expert Question"
    } finally {
      this.isUpdateExpertQuestionLoading = false
    }
    return false
  }

  expertsList: BaseUserData[] = []

  isExpertsLoading = true

  loadExpertsError = ""

  loadExperts = async (avatarId: string) => {
    this.isExpertsLoading = true
    this.loadExpertsError = ""
    try {
      const response = await knowledgeService.getKnowledgeExperts(avatarId)
      this.expertsList = response.data.data.experts ?? []
      return true
    } catch (error) {
      this.loadExpertsError = `Can't load experts`
    } finally {
      this.isExpertsLoading = false
    }
    return false
  }

  /**
   * @deprecated
   */
  reassignQuestionToExpert = async (
    questionId: string,
    expertIds: string[]
  ) => {
    try {
      this.isUpdateExpertQuestionLoading = true
      this.updateExpertQuestionError = null

      await knowledgeService.reassignQuestionToExpert(questionId, expertIds)
    } catch (error) {
      this.updateExpertQuestionError = `Failed to assign question to experts`
    } finally {
      this.isUpdateExpertQuestionLoading = false
    }
    return this.updateExpertQuestionError
  }

  assignExpertQuestionToSelf = async (
    avatarName: string,
    avatarId: string,
    question: string,
    delegatedToUserIds: string[],
    answer?: AnswerResponseData | AnswerResponseData[],
    questionNote?: string,
    summary?: string,
    suppressNotification: boolean = false
  ) => {
    try {
      const response =
        await knowledgeService.assignationQuestionDirectlyMultipleExperts({
          question,
          answer: JSON.stringify(answer),
          avatar: avatarName,
          avatarId,
          delegatedToUserIds,
          userNote: questionNote,
          summary,
          suppressNotification,
        })

      return response.data
    } catch (error) {
      return null
    }
  }

  assignQuestionToExpertDirectly = async (
    avatarName: string,
    avatarId: string,
    question: string,
    delegatedToUserIds: string[],
    answer?: AnswerResponseData | AnswerResponseData[],
    questionNote?: string,
    summary?: string
  ): Promise<SuccessResponse<Entity[]> | FailResponse> => {
    this.isUpdateExpertQuestionLoading = true
    this.updateExpertQuestionError = null
    try {
      const response =
        await knowledgeService.assignationQuestionDirectlyMultipleExperts({
          question,
          answer: JSON.stringify(answer),
          avatar: avatarName,
          avatarId,
          delegatedToUserIds,
          userNote: questionNote,
          summary,
          suppressNotification: delegatedToUserIds.length === 0,
        })
      return {
        status: "SUCCESS",
        data: response.data.data,
      }
    } catch (error) {
      this.updateExpertQuestionError = `Cancellation failed`
      return {
        status: "FAILED",
        message: this.updateExpertQuestionError,
      }
    } finally {
      this.isUpdateExpertQuestionLoading = false
    }
  }

  sendQuestionReminderToExperts = async (
    questionId: string,
    expertIds: string[]
  ): Promise<boolean> => {
    this.isSendReminderLoading = true
    try {
      const response = await knowledgeService.sendReminderToExperts(
        questionId,
        expertIds
      )
      return response.data.status === "SUCCESS"
    } catch (error) {
      return false
    } finally {
      this.isSendReminderLoading = false
    }
  }
}

export default KnowledgeStore
