import React from "react"
import clsx from "clsx"
import { Slate } from "slate-react"
import { observer, useLocalObservable } from "mobx-react-lite"

import Button from "@components/ui/Button/Button"
import Text from "@components/ui/Typography/Text"
import Icon from "@components/ui/Icon/Icon"
import {
  MarkdownEditorContextProvider,
  MarkdownEditorState,
} from "@components/ui/RichEditor/EditorContext"
import { EditorVoiceToTextContextProvider } from "@components/ui/RichEditor/EditorVoiceToTextContext"
import Editor from "@components/ui/RichEditor/MarkdownEditor"
import {
  markdownToSlate,
  slateToMarkdown,
} from "@components/ui/RichEditor/markdown-parser"
import { initEditor } from "@components/ui/RichEditor/utils"
import { useStore } from "@store"

import styles from "./MarkdownEditor.module.sass"

const DEFAULT_INFO_MESSAGE = `Once saved, this edited summary will be finalized as Experts Verified Citations for reference.`

interface MarkdownEditorProps {
  initialValue?: string
  className?: string
  infoMessage?: string
  onSave: (content: string) => Promise<void> | void
  onClose: () => void
}

const MarkdownEditor: React.FC<MarkdownEditorProps> = observer(
  ({
    onSave,
    onClose,
    infoMessage = DEFAULT_INFO_MESSAGE,
    initialValue,
    className,
  }) => {
    const { restrictionsStore: access } = useStore()
    const [value, setValue] = React.useState<string>(initialValue || "")
    const [error, setError] = React.useState<string>("")
    const [isSaving, setIsSaving] = React.useState(false)

    const handleSave = async () => {
      try {
        setIsSaving(true)
        await onSave(value)
      } finally {
        setIsSaving(false)
      }
    }

    const editor = React.useMemo(initEditor, [])

    const initialState: any[] = React.useMemo(
      () => markdownToSlate(initialValue || ""),
      []
    )

    const editorContext = useLocalObservable(() => new MarkdownEditorState())

    const canSave = !isSaving && !error && !!value

    return (
      <div className={clsx(styles.root, className)}>
        <div className={styles.body}>
          <Slate
            editor={editor}
            initialValue={initialState}
            onChange={(state) => {
              const res = slateToMarkdown(state)
              if (res.status === "FAILED") {
                setError(res.message || "Unacceptable format")
                return
              }
              setError("")
              setValue(res.data)
            }}
          >
            <MarkdownEditorContextProvider value={editorContext}>
              <EditorVoiceToTextContextProvider
                disable={!access.isVoiceRecognitionEnabled}
              >
                <Editor
                  readOnly={isSaving}
                  placeholder="Enter you answer..."
                  withError={!!error}
                />
              </EditorVoiceToTextContextProvider>
            </MarkdownEditorContextProvider>
          </Slate>
        </div>

        <div className={styles.footer}>
          <Text className={styles.footerText}>
            <Text variant="body2">
              <Icon name="info" inline />
            </Text>

            <Text variant="caption2" color="text50Color" inline>
              {infoMessage}
            </Text>
          </Text>

          <div className={styles.buttonContainer}>
            <Button
              onClick={onClose}
              variant="outlined"
              className={styles.closeButton}
              size="small"
            >
              Cancel
            </Button>
            <Button
              size="small"
              onClick={handleSave}
              variant="contained"
              color="primary"
              disabled={!canSave}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    )
  }
)

export default MarkdownEditor
