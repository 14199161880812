import React from "react"
import { observer } from "mobx-react-lite"

import Row from "@components/ui/BaseTable/Row"
import TextColumn from "@components/ui/SimpleTable/TextColumn"
import { useStore } from "@store"

const ProductsTableHeader: React.FC = observer(() => {
  const { restrictionsStore: access } = useStore()
  return (
    <Row>
      <TextColumn>Product Name</TextColumn>
      <TextColumn>Manufacturer</TextColumn>
      <TextColumn>Identifiers</TextColumn>
      <TextColumn>Linked Contents</TextColumn>
      {(access.canUpdateProduct || access.canDeleteProduct) && (
        <TextColumn width={38}> </TextColumn>
      )}
    </Row>
  )
})

export default ProductsTableHeader
