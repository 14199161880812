import React, { useEffect } from "react"
import { FormikProvider, useFormik } from "formik"
import { useAlert } from "react-alert"
import * as Yup from "yup"
import { observer } from "mobx-react-lite"

import BaseModal from "@components/modals/components/BaseModal/BaseModal"
import ModalTitle from "@components/modals/components/ModalTitle/ModalTitle"
import { ModalsTypes } from "@components/modals/constants"
import useModal from "@components/modals/useModal"
import FormTextInput from "@components/prototypes/form-elements/FormTextInput"
import Text from "@components/ui/Typography/Text"
import { useController, useStore } from "@store"
import Button from "@components/ui/Button/Button"
import Loader from "@components/ui/Loader/BarLoader"
import MultiSelect from "@components/ui/MultiSelect/MultiSelect"
import Templates from "@components/ui/Templates"
import { IdentifierDetails } from "@framework/types/identifier"

import styles from "./AddProductIdentifierModal.module.sass"

export interface AddProductIdentifierModalProps {
  onSuccess: (res: IdentifierDetails) => void
  parentCategoryId?: string
  companyId?: string
  invalidNames?: string[]
}
const AddProductIdentifierModal: React.FC<AddProductIdentifierModalProps> =
  observer(({ onSuccess, parentCategoryId, companyId, invalidNames }) => {
    const addProductIdentifierModal = useModal(
      ModalsTypes.ADD_PRODUCT_IDENTIFIER_MODAL
    )

    const validationSchema = Yup.object({
      companyId: Yup.string().required("Manufacturer is required"),
      name: Yup.string()
        .required("Name is required")
        .notOneOf(invalidNames || [], "Name is invalid"),
      parentCategoryId: Yup.string().optional(),
    })

    const {
      contentManagerStore: { companies, categories, loadingCompanies },
      identifierStore,
    } = useStore()
    const { loadingCreate, loadingList, error } = identifierStore

    const { identifierController, contentManagerController } = useController()

    const closeModal = () => {
      addProductIdentifierModal.hideModal()
    }

    const alert = useAlert()

    const formik = useFormik({
      initialValues: {
        companyId: companyId || "",
        name: "",
        parentCategoryId: parentCategoryId || "",
      },
      validationSchema,
      onSubmit: async (values) => {
        const res = await identifierController.createIdentifier(values)
        if (res !== null) {
          alert.success("Successfully created identifier")
          closeModal()
          onSuccess(res)
        }
      },
    })

    useEffect(() => {
      if (error) {
        alert.error(error)
        identifierStore.error = ""
      }
    }, [error])

    useEffect(() => {
      contentManagerController.getCompanies()
      return formik.resetForm()
    }, [])

    return (
      <BaseModal
        title={<ModalTitle titleText="Add Identifier" />}
        className={styles.root}
      >
        <Templates.RollScript
          footerSocket={
            <div className={styles.footer}>
              <Button size="medium" variant="outlined" onClick={closeModal}>
                Cancel
              </Button>
              <Button
                disabled={loadingCreate}
                after={loadingCreate && <Loader size="small" />}
                size="medium"
                color="primary"
                onClick={formik.submitForm}
              >
                Save
              </Button>
            </div>
          }
        >
          <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit}>
              <div className={styles.inputsContainer}>
                <div className={styles.inputWrapper}>
                  <Text variant="body2" className={styles.formInputHeading}>
                    Identifier Name
                  </Text>
                  <FormTextInput
                    value={formik.values.name}
                    name="name"
                    placeholder="Identifier Name"
                    onChange={(name) => {
                      formik.setFieldValue("name", name)
                    }}
                  />
                </div>

                <div className={styles.inputWrapper}>
                  <Text variant="body2" className={styles.formInputHeading}>
                    Manufacturer
                  </Text>
                  <MultiSelect
                    placeholder="Select the Manufacturer"
                    variant="secondary"
                    isLoading={loadingCompanies}
                    options={companies.map((company) => ({
                      id: company.id,
                      name: company.name,
                      value: company.name,
                    }))}
                    defaultSelectedItems={
                      formik.values.companyId ? [formik.values.companyId] : []
                    }
                    onSelectionChange={(selectedItems) => {
                      const selectedCompanyId = selectedItems[0] || ""
                      formik.setFieldTouched("companyId", true, true)
                      formik.setFieldValue("companyId", selectedCompanyId, true)
                      formik.setFieldValue("parentCategoryId", undefined, true)
                      contentManagerController.getCategories(selectedCompanyId)
                    }}
                    isRounded
                    isSingleSelect
                    error={!formik.values.companyId && formik.touched.companyId}
                  />
                </div>

                <div className={styles.inputWrapper}>
                  <Text variant="body2" className={styles.formInputHeading}>
                    Parent Identifier (Optional)
                  </Text>
                  <MultiSelect
                    placeholder="Add Parent Identifier "
                    variant="secondary"
                    options={categories.map((company) => ({
                      id: company.id,
                      name: company.name,
                      value: company.name,
                    }))}
                    defaultSelectedItems={
                      formik.values.parentCategoryId
                        ? [formik.values.parentCategoryId]
                        : []
                    }
                    onSelectionChange={(selectedItems) => {
                      const parentCategoryId = selectedItems[0] || ""
                      formik.setFieldValue("parentCategoryId", parentCategoryId)
                    }}
                    isRounded
                    isLoading={loadingList}
                    disabled={!formik.values.companyId}
                    isSingleSelect
                    error={
                      !!formik.errors.parentCategoryId &&
                      formik.touched.parentCategoryId
                    }
                  />
                </div>
              </div>
            </form>
          </FormikProvider>
        </Templates.RollScript>
      </BaseModal>
    )
  })

export default AddProductIdentifierModal
