import React, { useState } from "react"
import { observer } from "mobx-react-lite"
import { useAlert } from "react-alert"

import Text from "@components/ui/Typography/Text"
import Button from "@components/ui/Button/Button"
import List from "@components/ui/List/List"
import Icon from "@components/ui/Icon/Icon"
import Avatar from "@components/ui/Avatar/Avatar"
import { useStore } from "@store"
import Loader from "@components/ui/Loader/BarLoader"

import styles from "./ExpertCard.module.sass"

type ExpertCardProps = {
  expertId: string
  expertName: string
  avatarUrl?: string | null
  isPending: boolean
  questionId: string
}

const ExpertCard: React.FC<ExpertCardProps> = observer(
  ({ expertName, isPending, avatarUrl, questionId, expertId }) => {
    const {
      knowledgeStore: { sendQuestionReminderToExperts },
    } = useStore()
    const [isLoading, setLoading] = useState(false)
    const alert = useAlert()
    const handleSendReminder = async () => {
      setLoading(true)
      const success = await sendQuestionReminderToExperts(questionId, [
        expertId,
      ])
      setLoading(false)
      if (success) {
        alert.success("Reminder sent successfully")
      } else {
        alert.error("Failed to send Reminder")
      }
    }
    return (
      <div className={styles.root}>
        <List direction="row" justify="space-between" gutter="8">
          <Text className={styles.status} variant="caption2">
            <Icon
              name={isPending ? "timer-fill" : "shield-check-fill"}
              color={isPending ? "gold" : "green"}
            />
            {isPending ? "Waiting for Expert" : "Expert Verified"}
          </Text>
          <Button
            disabled={isLoading}
            after={isLoading && <Loader size="small" />}
            size="tiny"
            color="secondary"
            onClick={handleSendReminder}
          >
            Send Reminder
          </Button>
        </List>
        <div className={styles.expertInfo}>
          <Avatar name={avatarUrl || expertName} />
          <span className={styles.expertName}>{expertName}</span>
        </div>
      </div>
    )
  }
)

export default ExpertCard
