import React from "react"
import { FormikProvider, useFormik } from "formik"
import { useAlert } from "react-alert"
import * as Yup from "yup"
import { observer } from "mobx-react-lite"

import BaseModal from "@components/modals/components/BaseModal/BaseModal"
import ModalTitle from "@components/modals/components/ModalTitle/ModalTitle"
import { ModalsTypes } from "@components/modals/constants"
import useModal from "@components/modals/useModal"
import FormTextInput from "@components/prototypes/form-elements/FormTextInput"
import Text from "@components/ui/Typography/Text"
import SelectOption from "@components/ui/Dropdown/SelectOption"
import Button from "@components/ui/Button/Button"
import Loader from "@components/ui/Loader/BarLoader"
import { useStore } from "@store"
import { Attribute } from "@framework/types/product"

import styles from "./AddAttributeModal.module.sass"

export enum AttributeType {
  TECHNICAL_SPECIFICATION = "Technical specification",
  REGULATORY = "Regulatory Compliance",
  SAFETY = "Safety Data",
  CERTIFICATIONS = "Certifications",
  SUSTAINABILITY = "Sustainability",
  PACKAGING_AND_STORAGE = "Packaging & Storage",
}

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  type: Yup.string()
    .oneOf(Object.values(AttributeType))
    .required("Type is required"),
  value: Yup.string().required("Value is required"),
  unit: Yup.string().required("Unit is required"),
})

export type AddAttributeModalProps = {
  attribute?: Attribute
  productId: string
  onSuccess?: (attributes: Attribute) => void
}

const AddAttributeModal: React.FC<AddAttributeModalProps> = observer(
  ({ attribute, onSuccess, productId }) => {
    const addProductsModal = useModal(ModalsTypes.ADD_UPDATE_ATTRIBUTE_MODAL)
    const {
      productComparisonStore: {
        productStore: {
          isAddingAttribute,
          isUpdatingAttribute,
          addAttribute,
          updateAttribute,
        },
      },
    } = useStore()
    const alert = useAlert()

    const closeModal = () => {
      addProductsModal.hideModal()
    }

    const formik = useFormik({
      initialValues: {
        name: attribute?.name,
        type: attribute?.type,
        value: attribute?.value,
        unit: attribute?.unit,
      },
      validationSchema,
      onSubmit: async (values) => {
        const body = { ...values, id: attribute?.id }
        const data = await (attribute?.id ? updateAttribute : addAttribute)(
          productId,
          body as Attribute
        )

        if (data?.id) {
          alert.success(
            `Attribute ${values.name} ${
              attribute?.id ? "updated" : "added"
            } successfully`
          )
          onSuccess?.(data)
          closeModal()
        } else {
          alert.error(
            `Failed to ${attribute?.id ? "update" : "add"} attribute ${
              values.name
            }`
          )
        }
      },
    })

    const loading = isAddingAttribute || isUpdatingAttribute

    return (
      <BaseModal
        title={
          <ModalTitle
            titleText={`${attribute?.id ? "Update" : "Add"} Attribute`}
          />
        }
        onClose={closeModal}
        className={styles.root}
      >
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit} className={styles.form}>
            <div className={styles.formInput}>
              <Text variant="body2">Attribute Name</Text>
              <FormTextInput
                value={formik.values.name}
                name="name"
                placeholder="Enter Attribute Name"
                onChange={(name) => {
                  formik.setFieldValue("name", name)
                }}
              />
            </div>
            <div className={styles.formInput}>
              <Text variant="body2">Type</Text>
              <SelectOption
                error={formik.touched.type ? formik.errors.type : null}
                unit={["Type", "Types"]}
                placeholder="Select the type"
                options={Object.values(AttributeType)}
                value={formik.values.type}
                onChange={(selectedType) => {
                  formik.setFieldValue("type", selectedType)
                }}
                label={(type) => type}
              />
            </div>
            <div className={styles.formInput}>
              <Text variant="body2">Value</Text>
              <FormTextInput
                value={formik.values.value}
                name="value"
                placeholder="Enter Value"
                onChange={(value) => {
                  formik.setFieldValue("value", value)
                }}
              />
            </div>
            <div className={styles.formInput}>
              <Text variant="body2">Unit</Text>
              <FormTextInput
                value={formik.values.unit}
                name="unit"
                placeholder="Enter Unit"
                onChange={(unit) => {
                  formik.setFieldValue("unit", unit)
                }}
              />
            </div>
            <div className={styles.footer}>
              <Button size="medium" variant="outlined" onClick={closeModal}>
                Cancel
              </Button>
              <Button
                disabled={loading}
                after={loading && <Loader size="small" />}
                type="submit"
                size="medium"
                color="primary"
              >
                {attribute?.id ? "Update" : "Add"}
              </Button>
            </div>
          </form>
        </FormikProvider>
      </BaseModal>
    )
  }
)

export default AddAttributeModal
