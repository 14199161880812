import React from "react"
import { Editable } from "slate-react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"

import useToggle from "@components/hooks/useToggle"

import Toolbar from "./Toolbar"
import { renderElement, renderLeaf } from "./utils"
import { MarkdownEditorContext } from "./EditorContext"
import { EditorVoiceToTextContext } from "./EditorVoiceToTextContext"

import styles from "./MarkdownEditor.module.sass"

interface MarkdownEditorProps {
  placeholder?: string
  readOnly?: boolean
  withError?: boolean
}

export const Editor: React.FC<MarkdownEditorProps> = observer(
  ({ readOnly = false, placeholder, withError = false }) => {
    const editorContext = React.useContext(MarkdownEditorContext)
    const speechContext = React.useContext(EditorVoiceToTextContext)

    const focusToggle = useToggle(false)

    const previewMode = editorContext?.mode === "preview" || readOnly
    const nonInteractive = !!speechContext?.listening

    const handleRenderElement = React.useCallback(
      (props) => renderElement(props, previewMode),
      [previewMode]
    )

    const handleRenderLeaf = React.useCallback(
      (props) => renderLeaf(props, previewMode),
      [previewMode]
    )

    return (
      <div
        className={clsx(styles.root, {
          [styles.editable]: !readOnly,
          [styles.withError]: withError,
        })}
      >
        <div
          className={clsx(styles.container, {
            [styles.noneSelectable]: nonInteractive,
            [styles.focused]: focusToggle.isOpened,
          })}
        >
          {!readOnly && <Toolbar />}

          <Editable
            renderElement={handleRenderElement}
            renderLeaf={handleRenderLeaf}
            placeholder={placeholder}
            readOnly={previewMode || nonInteractive}
            onFocus={() => focusToggle.setOpened(true)}
            onBlur={() => focusToggle.setOpened(false)}
            className={styles.editor}
          />
        </div>
      </div>
    )
  }
)

export default Editor
